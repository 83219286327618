<template>
  <div class="controlNumberMain panel">
    <div>
      <v-tabs v-model="tab">
        <v-tab to="/controlNumbers/scan" exact> Scan </v-tab>
        <v-tab to="/controlNumbers/manual"> Manual </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="/controlNumbers/scan">
          <scanning></scanning>
        </v-tab-item>
        <v-tab-item value="/controlNumbers/manual"
          ><manual></manual
        ></v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import scanning from "@/components/nonPageComponents/ControlNumberScanning.vue";
import manual from "@/components/nonPageComponents/ControlNumberManual.vue";
export default {
  data() {
    return {
      tab: undefined
    };
  },
  components: {
    scanning: scanning,
    manual: manual
  }
};
</script>

<style scoped>
.controlNumberMain {
  margin: 15px;
}
</style>
