<template>
  <div>
    <div class="my-5">
      <v-btn class="mr-2" color="blue" @click="AcquireImage">
        <span class="white-text">Scan</span>
      </v-btn>
      <v-btn class="mr-2" color="blue" @click="selectSource">
        <span class="white-text">Change Scanner</span>
      </v-btn>
      <v-btn @click="clearAll" color="red">
        <span class="white-text">Clear All</span>
      </v-btn>
    </div>
    <div id="dwtcontrolContainer" style="display: none"></div>

    <div class="bootstrap">
      <b-table
        sticky-header="50vh"
        :no-border-collapse="true"
        :show-empty="true"
        :fields="tableFields"
        head-variant="light"
        sort-icon-left
        responsive="sm"
        hover
        :items="displayControlTitle"
      >
        <template v-slot:cell(close)="data">
          <v-btn
            v-if="data.item.controlNo !== null"
            icon
            @click="
              () => {
                const indexOfPairing = controlTitleArray.findIndex(
                  pairing => pairing.controlNo === data.item.controlNo
                );
                controlTitleArray.splice(indexOfPairing, 1);
              }
            "
            color="red"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </b-table>
    </div>
    <v-btn
      v-if="controlTitleArray.length > 0"
      class="success right"
      @click="save"
    >
      Submit
    </v-btn>

    <div v-if="errorImageArray.length > 0">
      <h2 class="red-text">
        The following {{ errorImageArray.length }} page
        <span v-if="errorImageArray.length > 1"> s </span>
        could not be read.
      </h2>
      <div class="img-container">
        <v-card
          @click="showImage(value)"
          hover
          v-for="value in errorImageArray"
          :key="value"
          class="img"
        >
          <v-img
            contain
            width="200px"
            :src="'data:image/jpg;base64,' + value"
          />
        </v-card>
      </div>
    </div>
    <v-dialog v-model="showImageModal">
      <v-card>
        <div class="right">
          <v-btn icon @click="showImageModal = false" color="red">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-img :src="'data:image/jpg;base64,' + fullsizeImage" />
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      content-class="error-dialogue"
      v-model="showErrorModal"
    >
      <v-card>
        <div class="right">
          <v-btn
            x-large
            icon
            @click="
              () => {
                showErrorModal = false;
                failedSaves = [];
              }
            "
            color="red"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div></v-card
      >
      <v-card-subtitle align="center" class="text-h4 font-weight-bold"
        >The following pairings did not save.</v-card-subtitle
      >
      <div class="bootstrap">
        <b-table
          outlined
          :items="failedSaves"
          :fields="errorTableFields"
          head-variant="light"
        ></b-table>
      </div>
    </v-dialog>
  </div>
</template>

<script>
/*global Dynamsoft*/
export default {
  data() {
    return {
      DWObject: {},
      errorImageArray: [],
      fullsizeImage: "",
      showImageModal: false,
      showErrorModal: false,
      failedSaves: [],
      controlTitleArray: [],
      tableFields: [{ key: "controlNo" }, { key: "titleNo" }, { key: "close" }],
      errorTableFields: [
        { key: "controlNo" },
        { key: "titleNo" },
        {
          key: "error",
          formatter: value => {
            return value.err.replace(/[\\']/g, "");
          }
        }
      ]
    };
  },
  computed: {
    displayControlTitle() {
      const temp = [];
      const sorted = JSON.parse(JSON.stringify(this.controlTitleArray));
      sorted.sort((a, b) => (a.controlNo > b.controlNo ? 1 : -1));
      for (let i = 0; i < sorted.length; i++) {
        temp.push(sorted[i]);
        if (sorted[i + 1] != undefined) {
          if (sorted[i + 1].controlNo != parseInt(sorted[i].controlNo) + 1) {
            temp.push({
              controlNo: null,
              titleNo: null,
              _rowVariant: "danger"
            });
          }
        }
      }
      return temp;
    }
  },
  mounted() {
    Dynamsoft.DWT.Load();
    Dynamsoft.DWT.RegisterEvent("OnWebTwainReady", this.onReady);
  },
  methods: {
    showImage(imageStr) {
      this.fullsizeImage = imageStr;
      this.showImageModal = true;
    },
    async AcquireImage() {
      this.errorImageArray = [];
      let OnAcquireImageSuccess, OnAcquireImageFailure;
      this.DWObject.CloseSource();
      this.DWObject.OpenSource();
      this.DWObject.IfShowUI = false;
      this.DWObject.Resolution = 300;
      this.DWObject.IfDuplexEnabled = false;
      this.DWObject.PixelType = 2; //rgb for accuracy
      this.DWObject.IfDisableSourceAfterAcquire = true;
      this.DWObject.IfShowProgressBar = false;
      this.DWObject.IfShowCancelDialogWhenImageTransfer = false;
      this.DWObject.IfShowIndicator = false;
      await this.DWObject.AcquireImage(
        OnAcquireImageSuccess,
        OnAcquireImageFailure
      );
      this.controlTitleArray.sort((a, b) =>
        a.controlNum > b.controlNum ? 1 : -1
      );
    },
    selectSource() {
      Dynamsoft.DWT.GetWebTwain("dwtcontrolContainer").SelectSource();
    },
    onTransfer() {
      this.readBarcode();
    },
    readBarcode() {
      this.DWObject.Addon.BarcodeReader.decode(
        this.DWObject.CurrentImageIndexInBuffer
      ).then(
        results => {
          if (results.length > 3) {
            results = results.filter(item => item.LocalizationResult.y1 > 1500);
          }
          results = results.sort((a, b) =>
            a.LocalizationResult.x1 > b.LocalizationResult.x1 ? 1 : -1
          );

          if (results.length === 3) {
            const initLength = this.controlTitleArray.length;

            this.controlTitleArray = this._.unionBy(
              this.controlTitleArray,
              [
                {
                  titleNo: results[2].BarcodeText,
                  controlNo: results[1].BarcodeText
                }
              ],
              "titleNo"
            );

            if (this.controlTitleArray.length === initLength) {
              this.$root.$emit(
                "push-alert",
                "Title Number " +
                  results[2].BarcodeText +
                  " has already been scanned.",
                {
                  color: "error"
                }
              );
            }
          } else {
            this.DWObject.ConvertToBase64(
              [this.DWObject.CurrentImageIndexInBuffer],
              1,
              base64Result => {
                const jpgString = base64Result.getData(
                  0,
                  base64Result.getLength()
                );
                this.errorImageArray.push(jpgString);
              },
              (errorCode, errorString) => {
                console.error(errorString);
              }
            );
          }
        },
        err => {
          console.error("barcodeScanProblem: " + err);
        }
      );
    },
    async onReady() {
      this.DWObject = Dynamsoft.DWT.GetWebTwain("dwtcontrolContainer");
      this.DWObject.RegisterEvent("OnPostTransfer", this.onTransfer);
      this.DWObject.IfUseTwainDSM = true; // Remove the option for WIA drivers
    },
    clearAll() {
      this.errorImageArray = [];
      this.fullsizeImage = "";
      this.controlTitleArray = [];
    },
    async save() {
      try {
        const response = await this.$api.submitControlNoRange(
          this.controlTitleArray
        );

        let errorCount = 0;

        response.forEach(pairing => {
          if (pairing["error"] !== undefined) {
            errorCount++;
            this.failedSaves.push(pairing);
          }
        });

        if (errorCount === 0) {
          this.clearAll();
          this.$root.$emit("push-alert", "Control numbers were saved.", {
            color: "success"
          });
        } else {
          this.showErrorModal = true;
        }
      } catch (e) {
        this.$root.$emit("push-alert", "There was an error saving.", {
          color: "error"
        });
      }
    }
  }
};
</script>

<style scoped>
.red-text {
  color: red;
}
::v-deep td {
  text-align: center;
}

::v-deep .error-dialogue {
  background-color: white;
}
.img {
  float: left;
}
.img-container {
  width: 100%;
}
.right {
  float: right;
}

#dwtcontrolContainer {
  display: none;
}
.dynamsoft-dialog-wrap {
  display: none !important;
}
.dynamsoft-dwt-dialogProgress {
  display: none !important;
}
.dynamsoft-backdrop {
  display: none !important;
}
</style>
