var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-5"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":_vm.AcquireImage}},[_c('span',{staticClass:"white-text"},[_vm._v("Scan")])]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":_vm.selectSource}},[_c('span',{staticClass:"white-text"},[_vm._v("Change Scanner")])]),_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.clearAll}},[_c('span',{staticClass:"white-text"},[_vm._v("Clear All")])])],1),_c('div',{staticStyle:{"display":"none"},attrs:{"id":"dwtcontrolContainer"}}),_c('div',{staticClass:"bootstrap"},[_c('b-table',{attrs:{"sticky-header":"50vh","no-border-collapse":true,"show-empty":true,"fields":_vm.tableFields,"head-variant":"light","sort-icon-left":"","responsive":"sm","hover":"","items":_vm.displayControlTitle},scopedSlots:_vm._u([{key:"cell(close)",fn:function(data){return [(data.item.controlNo !== null)?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function () {
              var indexOfPairing = _vm.controlTitleArray.findIndex(
                function (pairing) { return pairing.controlNo === data.item.controlNo; }
              );
              _vm.controlTitleArray.splice(indexOfPairing, 1);
            }}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()]}}])})],1),(_vm.controlTitleArray.length > 0)?_c('v-btn',{staticClass:"success right",on:{"click":_vm.save}},[_vm._v(" Submit ")]):_vm._e(),(_vm.errorImageArray.length > 0)?_c('div',[_c('h2',{staticClass:"red-text"},[_vm._v(" The following "+_vm._s(_vm.errorImageArray.length)+" page "),(_vm.errorImageArray.length > 1)?_c('span',[_vm._v(" s ")]):_vm._e(),_vm._v(" could not be read. ")]),_c('div',{staticClass:"img-container"},_vm._l((_vm.errorImageArray),function(value){return _c('v-card',{key:value,staticClass:"img",attrs:{"hover":""},on:{"click":function($event){return _vm.showImage(value)}}},[_c('v-img',{attrs:{"contain":"","width":"200px","src":'data:image/jpg;base64,' + value}})],1)}),1)]):_vm._e(),_c('v-dialog',{model:{value:(_vm.showImageModal),callback:function ($$v) {_vm.showImageModal=$$v},expression:"showImageModal"}},[_c('v-card',[_c('div',{staticClass:"right"},[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){_vm.showImageModal = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-img',{attrs:{"src":'data:image/jpg;base64,' + _vm.fullsizeImage}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","content-class":"error-dialogue"},model:{value:(_vm.showErrorModal),callback:function ($$v) {_vm.showErrorModal=$$v},expression:"showErrorModal"}},[_c('v-card',[_c('div',{staticClass:"right"},[_c('v-btn',{attrs:{"x-large":"","icon":"","color":"red"},on:{"click":function () {
              _vm.showErrorModal = false;
              _vm.failedSaves = [];
            }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-card-subtitle',{staticClass:"text-h4 font-weight-bold",attrs:{"align":"center"}},[_vm._v("The following pairings did not save.")]),_c('div',{staticClass:"bootstrap"},[_c('b-table',{attrs:{"outlined":"","items":_vm.failedSaves,"fields":_vm.errorTableFields,"head-variant":"light"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }