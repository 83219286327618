<template>
  <div>
    <div class="panel">
      <div class="flex">
        <v-text-field
          type="number"
          label="Title Number"
          v-model="titleNo"
          oninput="if (this.value.length > 11) this.value =
          this.value.slice(0, 11);"
          @focus="$event.target.select()"
          counter
          maxlength="11"
          :hint="titleNoHint"
          persistent-hint
        />
        <v-text-field
          type="number"
          label="Control Number"
          v-model="controlNo"
          oninput="if (this.value.length > 8) this.value = this.value.slice(0, 8);"
          @focus="$event.target.select()"
          counter
          maxlength="8"
          :hint="controlNoHint"
          persistent-hint
        />
      </div>
      <v-btn :disabled="!canUpdate" class="mr-3" color="blue" @click="update">
        {{ associatedControlNo === "" ? "Save" : "Update" }}
      </v-btn>
      <v-btn
        :disabled="!canRemove"
        class="mr-2"
        color="red"
        @click="removeAssociation"
      >
        Remove
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titleNo: "",
      controlNo: "",
      associatedTitleNo: "",
      associatedControlNo: "",
      isSearchingTitleNo: false,
      isSearchingControlNo: false
    };
  },
  methods: {
    async update() {
      await this.$api
        .submitControlNo(this.titleNo, this.controlNo, {
          404: e => {
            this.$root.$emit("push-alert", "Title Number not found", {
              color: "error"
            });
            throw e;
          }
        })
        .then(() => {
          this.$root.$emit("push-alert", "Association successful", {
            color: "success"
          });
          this.titleNo = "";
          this.controlNo = "";
        })
        .catch(() => {});
    },
    async removeAssociation() {
      const titleNoToRemove = this.associatedTitleNo
        ? this.associatedTitleNo
        : this.titleNo;
      await this.$api
        .removeAssociation(titleNoToRemove, {
          404: e => {
            this.$root.$emit("push-alert", "Title Number not found", {
              color: "error"
            });
            throw e;
          }
        })
        .then(() => {
          this.$root.$emit("push-alert", "Removal successful", {
            color: "success"
          });
          this.titleNo = "";
          this.controlNo = "";
        })
        .catch(() => {});
    },
    async getControlNo(titleNo) {
      this.associatedControlNo = "";

      this.isSearchingTitleNo = true;
      const response = await this.$api.getAssociatedControlNo(titleNo, {
        404: () => {
          this.$root.$emit(
            "push-alert",
            "Associated Control Number not found",
            { color: "error" }
          );
        }
      });
      this.associatedControlNo = this._.get(response, "[0].controlNo") || "";

      this.isSearchingTitleNo = false;
    },
    async getTitleNo(controlNo) {
      this.associatedTitleNo = "";

      this.isSearchingControlNo = true;
      const response = await this.$api.getAssociatedTitleNo(controlNo, {
        404: () => {
          this.$root.$emit("push-alert", "Associated Title Number not found", {
            color: "error"
          });
        }
      });
      this.associatedTitleNo = this._.get(response, "[0].titleNo") || "";

      this.isSearchingControlNo = false;
    }
  },
  computed: {
    canUpdate() {
      return (
        this.controlNoIsValid &&
        this.titleNoIsValid &&
        !(
          this.isSearchingControlNo ||
          this.isSearchingTitleNo ||
          this.controlNo === this.associatedControlNo ||
          this.titleNo === this.associatedTitleNo
        )
      );
    },
    canRemove() {
      return (
        (this.associatedTitleNo || this.associatedControlNo) &&
        !(this.isSearchingTitleNo || this.isSearchingControlNo)
      );
    },
    controlNoIsValid() {
      return Number(this.controlNo) && this.controlNo.length === 8;
    },
    titleNoIsValid() {
      return Number(this.titleNo) && this.titleNo.length === 11;
    },
    titleNoHint() {
      if (this.isSearchingTitleNo) return "Searching...";
      if (this.associatedControlNo)
        return "Associated Control Number: " + this.associatedControlNo;
      if (this.titleNoIsValid) return "Unassociated";
      return "";
    },
    controlNoHint() {
      if (this.isSearchingControlNo) return "Searching...";
      if (this.associatedTitleNo)
        return "Associated Title Number: " + this.associatedTitleNo;
      if (this.controlNoIsValid) return "Unassociated";
      return "";
    }
  },
  watch: {
    controlNo(controlNo) {
      this.associatedTitleNo = "";
      if (this.controlNoIsValid) this.getTitleNo(controlNo);
    },
    titleNo(titleNo) {
      this.associatedControlNo = "";
      if (this.titleNoIsValid) this.getControlNo(titleNo);
    }
  }
};
</script>

<style scoped>
.flex {
  justify-content: space-evenly;
}
.flex > div {
  max-width: 20rem;
}
.flex-around {
  justify-content: space-around;
}
</style>
